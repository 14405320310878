<svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.63398 2.5C10.0189 1.83333 10.9811 1.83333 11.366 2.5L18.7272 15.25C19.1121 15.9167 18.631 16.75 17.8612 16.75H3.13878C2.36898 16.75 1.88786 15.9167 2.27276 15.25L9.63398 2.5Z" fill="#DC4942"/>
    <path d="M11.444 6.276L11.204 12.024H9.464L9.224 6.276H11.444ZM10.364 15.096C10.004 15.096 9.708 14.992 9.476 14.784C9.252 14.568 9.14 14.304 9.14 13.992C9.14 13.672 9.252 13.404 9.476 13.188C9.708 12.972 10.004 12.864 10.364 12.864C10.716 12.864 11.004 12.972 11.228 13.188C11.46 13.404 11.576 13.672 11.576 13.992C11.576 14.304 11.46 14.568 11.228 14.784C11.004 14.992 10.716 15.096 10.364 15.096Z" fill="#262B46"/>
</svg>

<style lang="scss">
    svg {
        min-width: 21px;
        min-height: 22px;
      padding: 0 6px;
    }
</style>