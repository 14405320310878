<script>
    import InvalidInputIcon from "$lib/InvalidInputIcon.svelte";

    export let message = "Invalid Sign In";
</script>

<div class="container">
    <InvalidInputIcon/>
    <div class="message">
        {message}
    </div>
</div>

<style lang="scss">
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 9px;
  }

  .message {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: var(--color-background-dark-red);
  }
</style>